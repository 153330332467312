<template>
  <!-- 柱状统计图 -->
  <div class="bar-chart-size" v-loading="loading">
    <div
      class="chart-box"
      ref="chartBox"
      style="width: 100%; height: 100%"
      v-if="echartData.length !== 0"
    ></div>
    <dataNull v-if="echartData.length === 0"></dataNull>
  </div>
</template>
<script>
import dataNull from "../chart/dataNull.vue";
// vue文件中引入echarts工具
let echarts = require("echarts");
// 以下的组件按需引入
require("echarts/lib/chart/bar");
require("echarts/lib/component/tooltip"); // tooltip组件
require("echarts/lib/component/title"); // title组件
require("echarts/lib/component/legend"); // legend组件
require("echarts/lib/component/dataZoom");

export default {
  props: [
    "echartData", //柱状图数据，格式：{name:'',value:''}
    "barWidth", //柱子宽度
    "barColor", //柱子颜色
    "textColor", //坐标轴文字颜色
    "yUnit", //纵坐标单位
    "echartSize", //柱状图位置，{ x: 58, x2: 25, y: 45, y2: 40 }
    "radiusSize", //柱状图圆角，[0, 0, 0, 0]
    "maxLength", //最大一行数据
    "isHideY", //是否隐藏Y轴
    "isHideYline", //是否显示Y轴线
    "isTopData", //是否显示顶部数值
    //"maxNum", //最大数据
  ],
  data() {
    return {
      //图表
      barChart: {},

      barOption: null,

      showBarLength: this.maxLength || 6,
      loading: true,
      timer: 0,
    };
  },
  computed: {},
  components: {
    dataNull,
  },
  watch: {
    echartData: {
      handler: function (val, oldVal) {
        /**
         * 为解决 各种不同默认数据 以及接口返回数据情况情况做的处理
         */
        let oldValStr = JSON.stringify(oldVal);
        let valStr = JSON.stringify(val);
        if (valStr && !oldValStr) {
          this.loading = true;
          this.timer = setTimeout(() => {
            this.drawChart();
            this.loading = false;
            clearTimeout(this.timer);
          }, 3000);
          return;
        }
        // else if (valStr === oldValStr) {
        //   return;
        // }
        else
        {
          clearTimeout(this.timer);
        }
        this.loading = true;
        this.drawChart();
        this.loading = false;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // this.drawChart();
  },
  beforeDestroy() {
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
  },
  methods: {
    //数据更新
    drawChart: function () {
      if (this.echartData.length === 0) {
        return false;
      }
      // 整理数据
      let xAxisText = [];
      let dataList = [];
      let allData = this.echartData;
      this.echartData.forEach((item) => {
        xAxisText.push(item.name);
        dataList.push(item.value);
      });
      //整理柱状图配置
      this.barOption = {
        title: {},
        tooltip: {
          show: true,
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "{c}" + this.yUnit,
        },
        color: this.barColor || "#000000",
        grid: this.echartSize,
        xAxis: {
          type: "category",
          data: xAxisText,
          axisLabel: {
            show: true,
            textStyle: {
              color: this.textColor || "#000000", //这里是改变字体颜色
            },
          },
          //隐藏刻度线
          axisTick: {
            show: false,
          },
          //隐藏轴线
          axisLine: {
            show: true,
            lineStyle: {
              color: "#EEEEEE",
            },
          },
        },
        yAxis: {
          type: "value",
          name: "",
          nameTextStyle: {
            color: this.textColor || "#000000",
            padding: [0, 20, 0, 0],
          },
          axisLabel: {
            show: this.isHideY,
            textStyle: {
              color: this.textColor || "#000000", //这里是改变字体颜色
            },
            formatter: "{value}" + this.yUnit,
          },
          splitLine: {
            show: this.isHideYline,
            lineStyle: {
              color: ["#EFEFEF"],
              width: 1,
              type: "solid",
            },
          },
          //隐藏刻度线
          axisTick: {
            show: false,
          },
          //隐藏轴线
          axisLine: {
            show: this.isHideYline,
            lineStyle: {
              color: "#EEEEEE",
            },
          },
          //min: 0,
          //max: this.maxNum,
        },
        series: [
          {
            data: dataList,
            type: "bar",
            barWidth: this.barWidth || 10, //柱图宽度
            barCategoryGap: "5%",
            itemStyle: {
              emphasis: {
                barBorderRadius: this.radiusSize || [0, 0, 0, 0],
              },
              // 主要部分
              normal: {
                barBorderRadius: this.radiusSize || [0, 0, 0, 0],
                label: {
                  show: this.isTopData,
                  formatter: function (arg) {
                    //console.log(allData,arg.dataIndex)
                    let showWord = "";
                    if (allData[arg.dataIndex].percent) {
                      //品牌详情--门店分布柱状图顶部数据为：数值+百分比
                      showWord =
                        arg.value + "\n" + allData[arg.dataIndex].percent;
                    } else {
                      showWord = arg.value;
                    }
                    return showWord;
                  }, // 显示数值
                  position: "top", // 数值在柱体上方
                  textStyle: {
                    color: "#848295", //这里是改变字体颜色
                  },
                },
              },
            },
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart = echarts.init(this.$refs.chartBox);
      // 绘制图表
      this.myChart.setOption(this.barOption);

      //动态改变图表尺寸（适配）
      setTimeout(() => {
        this.myChart.resize();
      }, 20);
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    },
  },
};
</script>
<style lang="less">
.bar-chart-size {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  .chart-box {
    width: 100%;
    height: 100%;
  }
}
</style>
